import React from "react";

interface Props {
    id: string;
    data: any;
    imgSrc: string;
    swap: boolean
}


/** swaps image and text cells if swap bool is true*/
function render(props: Props) {
    if (props.swap) {
        return (
            <div id={props.id} className="pagerow">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="pagerow-text">
                            <h2>{props.data.header}</h2>
                            <p>
                                {props.data
                                    ? props.data.paragraph
                                    : "loading..."}
                            </p>
                            <div className="list-style">
                                <div className="col-lg-10 col-sm-10 col-xs-12">
                                    <ul>
                                        {props.data
                                            ? props.data.List?.map((d: any, i: number) => (
                                                <li key={`${d}-${i}`}>{d}</li>
                                            ))
                                            : "loading"}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        {" "}
                        <img
                            src={props.imgSrc}
                            className="img-responsive"
                            alt=""
                        />{" "}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div id={props.id} className="pagerow">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        {" "}
                        <img
                            src={props.imgSrc}
                            className="img-responsive"
                            alt=""
                        />{" "}
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="pagerow-text">
                            <h2>{props.data.header}</h2>
                            <p>
                                {props.data
                                    ? props.data.paragraph
                                    : "loading..."}
                            </p>
                            <div className="list-style">
                                <div className="col-lg-10 col-sm-10 col-xs-12">
                                    <ul>
                                        {props.data
                                            ? props.data.List?.map((d: any, i: number) => (
                                                <li key={`${d}-${i}`}>{d}</li>
                                            ))
                                            : "loading"}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



export const HomePageSegment = (props: Props) => {
    return render(props)
};
