export const firebaseConfig = {
    apiKey: "AIzaSyA6Pk4p8XQvGESLiwoJ7GWKEnT34gI-Wgc",
    authDomain: "ekkoappv0.firebaseapp.com",
    databaseURL: "https://ekkoappv0.firebaseio.com",
    projectId: "ekkoappv0",
    storageBucket: "ekkoappv0.appspot.com",
    messagingSenderId: "297704214098",
    appId: "1:297704214098:web:efd31c1cf1f70f5527998b",
    measurementId: "G-QXPE45C4WB",
};
