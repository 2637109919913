import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Toast from 'react-bootstrap/Toast'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'


import { Firebase } from '../firebase/Firebase'
import time from './images/time.svg'
import homerun from './images/homerun.svg'
import SVG from './SVG'


interface Props extends RouteComponentProps {

}
export const TZForm: React.FC<Props> = (props: Props) => {
    const isRegisterRoute = window.location.href.includes("/register")

    const [tz] = React.useState<string>(() => Intl.DateTimeFormat().resolvedOptions().timeZone)
    const [email, setEmail] = React.useState<string>(() => props.location.search.split("=")[1])

    const [pwd, setPwd] = React.useState<string>("")
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
    const [done, setDone] = React.useState<boolean>(false)
    const [error, setError] = React.useState<{ message: string; code: number } | null>(null)


    const renderError = () => {
        if (error && error.message) {
            return (
                <Row>
                    <Col xs={6}>
                        <Toast show={error.code !== 200} onClose={() => setError(null)} style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            backgroundColor: "red"
                        }}>
                            <Toast.Header>
                                <strong className="mr-auto">Computer says no...</strong>
                            </Toast.Header>
                            <Toast.Body>Sorry. {error.message}. <a href="mailto:hello@getremynd.com?subject=Missing%20user" target="_blank" rel="noopener noreferrer" style={{ "color": "white" }}> Report this.</a></Toast.Body>
                        </Toast>
                    </Col>
                </Row>
            )
        }
    }

    const renderOk = () => {
        // remove the error message if tz update successful
        if (done && error) setError(null)

        return (
            <Row>
                <div style={{ textAlign: "center", margin: "auto" }}>
                    <h3 > Great, all done!</h3>
                    <div>You can close the browser now <span role="img" aria-label="thumbs up">👍</span></div>
                </div>

                <SVG src={homerun} alt="All done" />
            </Row>
        )

    }

    const renderForm = () => {
        return (
            <>

                {!done && <SVG src={time} alt="Confirm timezone" />}

                < form onSubmit={submitForm} >
                    <h3> Just confirming your timezone and the email you used to send us links!</h3>

                    {
                        isRegisterRoute && tz ? (
                            <div className="form-group">
                                <label style={{ fontWeight: "bold" }} >Your timezone</label>
                                <input readOnly type="text" className="form-control-plaintext" value={tz} />
                            </div>
                        ) : null
                    }

                    <div className="form-group">
                        <label style={{ fontWeight: "bold" }}>Your Email address</label>
                        <input type="email" value={email} className="form-control-plaintext" placeholder="Enter email" onChange={() => { }} />
                    </div>

                    <Button variant="primary" disabled={isSubmitting} type="submit">
                        {isSubmitting && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                        {isSubmitting ? "Confirming..." : "Confirm"}
                    </Button>
                </form >
            </>
        )
    }



    const renderPage = () => {
        return (
            <>
                {renderError()}
                {!done ? renderForm() : renderOk()}
            </>
        );
    }

    const submitForm = async (event: any) => {
        event.preventDefault()

        // send email and tz to db
        setIsSubmitting(true)
        const apiRes = await Firebase.registerWithTz({
            tz, email, pwd
        })
        setIsSubmitting(false)
        if (apiRes.data.code !== 200) {
            setError(apiRes.data)
        } else {
            setDone(true)
        }

        setPwd("")
    }

    return renderPage()

}

export const TimeZoneForm = withRouter(TZForm)
