import React from "react";

interface Props {
  data: any
}

export const Banner: React.FC<Props> = (props: Props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>
                  {props.data ? props.data.paragraph : "Loading"}
                </p>
                <a
                  href="#howto"
                  className="btn btn-custom btn-lg page-scroll "
                >
                  Learn More
                  </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}





