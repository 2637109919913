import React from "react";

const Footer = () => {
    return (
        <footer id="footer" style={{ marginTop: "15px", fontSize: "11px" }}>
            <div className="container text-center" >
                <p>
                    {/* ts-ignore jsx-a11y/accessible-emoji */}
                    Made with 💜 &nbsp;in Melbourne, Australia, during the global
                    pandemic by{" "}
                    <a href="https://twitter.com/zubinpratap" target="_blank" rel="noopener noreferrer">
                        Zubin
                    </a>
                    .
                </p>
            </div>
        </footer >
    );
};

export default Footer;
