import React from 'react'


interface Props {
    src: string;
    alt: string
}
const SVG = (props: Props) => {
    return (
        <div style={{ marginTop: "25px", marginBottom: "25px" }}>
            <img style={{ width: " 75%" }} src={props.src} alt={props.alt} />
        </div>
    )
}

export default SVG
