import React from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import Spinner from 'react-bootstrap/esm/Spinner'
import Button from 'react-bootstrap/esm/Button'


interface Props extends RouteComponentProps {
    type: "signup" | "signin"
}


export const AuthForm: React.FC<Props> = (props: Props) => {
    // state hooks
    const [email, setEmail] = React.useState<string>("")
    const [pwd, setPwd] = React.useState<string>("")
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)


    const submitForm = async (event: any) => {
        event.preventDefault()
        setIsSubmitting(true)

        // redirect TODO
        setTimeout(() => {
            props.history.push("/")
        }, 2400)
    }


    return (
        <>
            <h3> Let's get you signed up</h3>
            <p>Having an account means you can view links you've sent in the past 2 weeks. We can only know which links are yours by connecting it with your email. </p>
            <p> Having a password means your links are only viewed on login - that's to protect your privacy. </p>
            < form onSubmit={submitForm} >
                <div className="form-group">
                    <label style={{ fontWeight: "bold" }}>Your Email address</label>
                    <input type="email" value={email} className="form-control" placeholder="Enter email" onChange={(event) => { setEmail(event.target.value) }} />
                </div>

                <div className="form-group">
                    <label style={{ fontWeight: "bold" }}>Password</label>
                    <input required type="password" value={pwd} className="form-control" placeholder="Enter password" onChange={(event) => { setPwd(event.target.value) }} />
                </div>

                <Button variant="primary" disabled={isSubmitting} type="submit">
                    {isSubmitting && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />}
                    {isSubmitting ? "Signing up..." : "Signup"}
                </Button>
            </form >
        </>
    )
}

