import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import { PURPLE } from './Home'
import { SIGNUP, HOWTO, ABOUT } from '../constants/Routes'

interface Props { }


export const NavigationHeader: React.FC = (props: Props) => {
    const linkStyle = { fontSize: "14px", color: "white" };
    const navLinks = [
        {
            text: "About Remynd",
            url: ABOUT
        },
        {
            text: "How It Works",
            url: HOWTO
        },
        // {
        //     text: "Sign Up",
        //     url: SIGNUP
        // }
    ]

    return (
        <Navbar expand="md" style={{ backgroundColor: PURPLE, marginBottom: "-2px" }} variant="dark">
            {/* <Navbar.Brand href="#">Remynd</Navbar.Brand> */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto">
                    {navLinks.map(({ text, url }) => (
                        <Nav.Link key={url} href={url} style={linkStyle}> {text} </Nav.Link>
                    ))}
                </Nav>
            </Navbar.Collapse>
        </Navbar >
    )
}
