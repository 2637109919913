import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import * as ROUTES from '../constants/Routes'
import { Home } from './Home';
import { Register } from './Register';
import Footer from './Footer';
import Container from 'react-bootstrap/esm/Container';
import { NavigationHeader } from './Navbar';
import { AuthForm } from './AuthForm';


function App() {

  return (
    <Container>
      <NavigationHeader />
      <Router>
        <Switch >
          <Route exact path={ROUTES.REGISTER_TZ} component={Register} />
          <Route exact path={ROUTES.SIGNUP} render={(props) => (<AuthForm type="signup" {...props} />)} />
          {/* keep the home route last so it acts as catchall */}
          <Route path={ROUTES.HOME} component={Home} />
        </Switch>
      </Router>
      <Footer />
    </Container>
  )
}

export default App;
