

import React from 'react'
import { TimeZoneForm } from './TZForm'
import Container from 'react-bootstrap/esm/Container'

export interface ApiResponse {
    message: string;
    code: number;
}


export const Register = () => {
    return (
        <Container>
            <TimeZoneForm />
        </Container>

    )
}


