import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/esm/Row';

import { Banner } from './Banner';
import JsonData from "../data/data.json";
import { HomePageSegment } from './HomePageSegment';

import sendlinks from "./images/sendlinks.svg"
import thinking from "./images/thinking.svg"

export const PURPLE = "#6c63ff"


export const Home = () => {
    const [landingPageData] = React.useState(() => JsonData);

    return (
        <>
            <Banner data={landingPageData.Header} />
            <Container>
                <Row>
                    <HomePageSegment data={landingPageData.HowTo} id={"howto"} imgSrc={sendlinks} swap={false} />
                </Row>

                {/* call to action */}
                <div style={{ margin: 'auto', width: "75%", backgroundColor: `${PURPLE}`, color: "white", opacity: "0.75" }}>
                    <p style={{ textAlign: "center" }}>  Try it now! Try sharing a link from inside an app, or pasting some links into an email and sending it to &nbsp;
                            <a style={{ color: "#ffffff" }} href="mailto:later@getremynd.com" target="_blank" rel="noopener noreferrer">
                            <strong>later@getremynd.com</strong>
                        </a>
                    </p>
                </div>

                <Row>
                    <HomePageSegment data={landingPageData.About} id={"about"} imgSrc={thinking} swap={true} />
                </Row>
            </Container>
        </>
    )
}

